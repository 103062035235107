import { getTask } from '@/react/docker/tasks/queries/useTask';
import { getTasks } from '@/react/docker/proxy/queries/tasks/useTasks';

import { TaskViewModel } from '../models/task';

angular.module('portainer.docker').factory('TaskService', TaskServiceFactory);

/* @ngInject */
function TaskServiceFactory(AngularToReact) {
  const { useAxios, injectEnvironmentId } = AngularToReact;

  return {
    task: useAxios(injectEnvironmentId(taskAngularJS)), // task edit
    tasks: useAxios(injectEnvironmentId(tasksAngularJS)), // services list + service edit + swarm visualizer + stack edit
  };

  /**
   * @param {EnvironmentId} environmentId Injected
   * @param {TaskId} id
   */
  async function taskAngularJS(environmentId, id) {
    const data = await getTask(environmentId, id);
    return new TaskViewModel(data);
  }

  /**
   * @param {EnvironmentId} environmentId Injected
   * @param {*} filters
   */
  async function tasksAngularJS(environmentId, filters) {
    const data = await getTasks(environmentId, filters);
    return data.map((t) => new TaskViewModel(t));
  }
}
