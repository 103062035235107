import { useQuery } from 'react-query';

import { EnvironmentId } from '@/react/portainer/environments/types';
import axios, { parseAxiosError } from '@/portainer/services/axios';
import { ServiceId } from '@/react/docker/services/types';
import { ContainerId } from '@/react/docker/containers/types';
import { withAgentTargetHeader } from '@/react/docker/proxy/queries/utils';

import { ImageStatus, ResourceID, ResourceType } from '../types';

import { queryKeys } from './query-keys';

export function useImageStatus(
  environmentId: number,
  resourceId: ResourceID,
  resourceType: ResourceType,
  nodeName = '',
  enabled = false
) {
  return useQuery(
    queryKeys.item({ environmentId, resourceType, resourceId }),
    () => getImageStatus(environmentId, resourceId, resourceType, nodeName),
    {
      enabled,
      // the loading spinner now shows for refetching after clearing cache, so avoid refetching on window focus for less flickering
      refetchOnWindowFocus: false,
      // don't retry on error, the user can retry manually if needed
      retry: false,
    }
  );
}

export function getImageStatus(
  environmentId: number,
  resourceId: ResourceID,
  resourceType: ResourceType,
  nodeName = '',
  refresh = false
) {
  switch (resourceType) {
    case 'container':
      return getContainerImagesStatus(
        environmentId,
        resourceId,
        nodeName,
        refresh
      );
    case 'service':
      return getServiceImagesStatus(environmentId, resourceId, refresh);
    case 'stack':
      return getStackImagesStatus(resourceId, refresh);
    default:
      throw new Error(`Unknown resource type: ${resourceType}`);
  }
}

export async function getContainerImagesStatus(
  environmentId: EnvironmentId,
  containerID: ContainerId,
  nodeName: string,
  refresh = false
) {
  const params = { refresh };
  try {
    const { data } = await axios.get<ImageStatus>(
      `/docker/${environmentId}/containers/${containerID}/image_status`,
      { headers: { ...withAgentTargetHeader(nodeName) }, params }
    );
    return data;
  } catch (e) {
    throw parseAxiosError(
      e,
      `Unable to retrieve image status for container: ${containerID}`
    );
  }
}

export async function getServiceImagesStatus(
  environmentId: EnvironmentId,
  serviceID: ServiceId,
  refresh = false
) {
  const params = { refresh };
  try {
    const { data } = await axios.get<ImageStatus>(
      `/docker/${environmentId}/services/${serviceID}/image_status`,
      { params }
    );
    return data;
  } catch (e) {
    throw parseAxiosError(
      e,
      `Unable to retrieve image status for service: ${serviceID}`
    );
  }
}

export async function getStackImagesStatus(id: string, refresh = false) {
  const params = { refresh };
  try {
    const { data } = await axios.get<ImageStatus>(
      `/stacks/${id}/images_status`,
      { params }
    );
    return data;
  } catch (e) {
    throw parseAxiosError(
      e,
      `Unable to retrieve image status for stack: ${id}`
    );
  }
}
